import React, { useContext } from 'react';
import { navigate } from 'gatsby';
import { AuthContext } from '../context/Auth';

function Menu() {
    const { user } = useContext(AuthContext) || {user: () => {}};
    return (
        <div className="bg-white">
            <div className="absolute top-0 right-0 m-2 p-2 h-8">
                <button className="text-white font-semibold" onClick={() => { navigate('/') }}>X</button>
            </div>
            <div className="h-36 bg-yellow-600 flex items-end bg-opacity-80">
                <div className="flex p-1 mb-6 ml-3">

                    <div className="rounded-full h-12 w-12 bg-white flex justify-center items-center text-lg text-yellow-600 font-semibold">
                        {user?.name ? user?.name[0] : ""}
                    </div>
                    <div className="ml-3 flex flex-col items-start">
                        <h1 className="text-white text-lg">{user?.name ?? ""}</h1>
                        {/* <button className="text-white text-xs mt-1">{'Edit Profile >'}</button> */}
                    </div>
                </div>
            </div>
            <div className="p-1 flex flex-col items-start">
                <button className="flex h-16 items-center ml-4" onClick={() => { navigate('/consultAstro') }}>
                    <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.0794 5.54829L15.4163 4.20851C17.3561 6.25883 17.3557 9.47341 15.4152 11.5233L14.0794 10.1856C15.2805 8.87519 15.2805 6.85869 14.0794 5.54829ZM7.16866 4.43268L7.16851 4.43268C5.89043 4.43397 4.84991 5.46072 4.8316 6.73868C4.81329 8.01663 5.82395 9.07277 7.10147 9.11069C8.23101 9.14422 9.19952 8.37078 9.44849 7.30863H9.50819V6.87073V6.7722C9.50819 5.48012 8.46075 4.43268 7.16866 4.43268ZM2.64206 18.6657H0.751816C0.831519 15.1901 3.67392 12.3971 7.16866 12.3971C10.6634 12.3971 13.5058 15.1901 13.5855 18.6657H11.6953C11.6161 16.2338 9.61979 14.2866 7.16866 14.2866C4.71754 14.2866 2.72119 16.2338 2.64206 18.6657ZM18.514 1.11285C22.1621 4.87388 22.1621 10.86 18.514 14.6211L17.1779 13.285C18.5776 11.8307 19.3616 9.88927 19.3616 7.86696C19.3616 5.84465 18.5776 3.90319 17.1779 2.44891L18.514 1.11285ZM7.16866 11.0012C4.83303 11.0012 2.93962 9.10784 2.93962 6.7722C2.93962 4.43656 4.83303 2.54316 7.16866 2.54316C9.5043 2.54316 11.3977 4.43656 11.3977 6.7722C11.3977 7.89381 10.9522 8.96949 10.159 9.76259C9.36595 10.5557 8.29028 11.0012 7.16866 11.0012Z" fill="black" stroke="white" strokeWidth="0.3" />
                    </svg>
                    <h3 className="ml-4">Consult</h3>

                </button>
                <button className="flex h-16 items-center ml-4" onClick={() => { navigate('/consultHistory') }}>
                    <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.0794 5.54829L15.4163 4.20851C17.3561 6.25883 17.3557 9.47341 15.4152 11.5233L14.0794 10.1856C15.2805 8.87519 15.2805 6.85869 14.0794 5.54829ZM7.16866 4.43268L7.16851 4.43268C5.89043 4.43397 4.84991 5.46072 4.8316 6.73868C4.81329 8.01663 5.82395 9.07277 7.10147 9.11069C8.23101 9.14422 9.19952 8.37078 9.44849 7.30863H9.50819V6.87073V6.7722C9.50819 5.48012 8.46075 4.43268 7.16866 4.43268ZM2.64206 18.6657H0.751816C0.831519 15.1901 3.67392 12.3971 7.16866 12.3971C10.6634 12.3971 13.5058 15.1901 13.5855 18.6657H11.6953C11.6161 16.2338 9.61979 14.2866 7.16866 14.2866C4.71754 14.2866 2.72119 16.2338 2.64206 18.6657ZM18.514 1.11285C22.1621 4.87388 22.1621 10.86 18.514 14.6211L17.1779 13.285C18.5776 11.8307 19.3616 9.88927 19.3616 7.86696C19.3616 5.84465 18.5776 3.90319 17.1779 2.44891L18.514 1.11285ZM7.16866 11.0012C4.83303 11.0012 2.93962 9.10784 2.93962 6.7722C2.93962 4.43656 4.83303 2.54316 7.16866 2.54316C9.5043 2.54316 11.3977 4.43656 11.3977 6.7722C11.3977 7.89381 10.9522 8.96949 10.159 9.76259C9.36595 10.5557 8.29028 11.0012 7.16866 11.0012Z" fill="black" stroke="white" strokeWidth="0.3" />
                    </svg>
                    <h3 className="ml-4">Consult History</h3>

                </button>
            </div>

        </div>
    )
}

export default Menu
